import {
    Component,
    DestroyRef,
    HostListener,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { NewPasswordDialog } from 'src/app/dialogs/new-password-dialog/new-password-dialog.component';
import { SelectUserDialog } from 'src/app/dialogs/select-user-dialog/select-user-dialog.component';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import {
    FeatureFlag,
    SettingsService,
    hasFlagAccess,
} from '../../services/user/settings.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy {
    sections = [
        {
            id: 'account',
            title: 'settings.account',
            icon: 'account-circle',
        },
        {
            id: 'date-time',
            title: 'settings.date_time',
            icon: 'calendar-clock',
        },
        {
            id: 'customer',
            title: 'organization',
            icon: 'account-group',
        },
        {
            id: 'extras',
            title: 'settings.extras',
            icon: 'shimmer',
        },
    ];
    selectedIndex = 0;
    destroyRef = inject(DestroyRef);

    public dateTimePreview = '-';

    get selectedSection(): string {
        return this.sections[this.selectedIndex].id;
    }

    get filteredSettings() {
        return this.settings.allSettings.filter(
            (setting) => setting.id.split('.')[0] == this.selectedSection
        );
    }

    get featureFlagOptions() {
        return Object.values(FeatureFlag).filter((flag) => hasFlagAccess(flag));
    }

    constructor(
        public settings: SettingsService,
        private appService: AppService,
        private dateTimeService: DateTimeService,
        private dialog: DialogService,
        private auth: AuthService,
        private snackBar: SnackBarService,
        private api: ApiService
    ) {
        this.settings.isSaved$
            .pipe(takeUntilDestroyed())
            .subscribe((status) => (this.appService.isSaved = status));
    }

    ngOnInit(): void {
        timer(0, 1000)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.dateTimePreview = this.dateTimeService.dateTimeString();
            });
    }

    @HostListener('window:beforeunload')
    beforeUnloadHander() {
        this.settings.save();
    }

    ngOnDestroy(): void {
        this.settings.save();
    }

    async resetPassword() {
        await this.dialog.open(NewPasswordDialog, {
            data: { dialogType: 'update' },
        });
    }

    async impersonate() {
        const selectedUser = await this.dialog.open(SelectUserDialog, {
            width: 'auto',
            data: {
                title: 'settings.impersonate',
                description: 'settings.impersonate.description',
                submitLabel: 'settings.impersonate',
            },
        });

        const data = { impersonateUserId: selectedUser.id };
        try {
            await this.api.settings.update(data);
            this.auth.signOut();
        } catch (error) {
            this.snackBar.open('settings.impersonate.error');
        }
    }

    selectFeatureFlag(flag: string, selected: boolean) {
        const featureFlags = new Set(
            this.settings.get('extras.feature-flags').split(' ')
        );
        if (selected) {
            featureFlags.add(flag);
        } else {
            featureFlags.delete(flag);
        }
        this.settings.set(
            'extras.feature-flags',
            Array.from(featureFlags).join(' ')
        );
    }
}
