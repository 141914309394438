import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { UserRole } from 'src/app/services/constants';
import { User } from 'src/app/shared/models/user';

@Component({
    templateUrl: './select-user-dialog.component.html',
})
export class SelectUserDialog implements OnInit {
    users: User[] = [];
    filterTerm: string;
    userId: number = null;
    destroyRef = inject(DestroyRef);

    get userSelected() {
        return Boolean(this.userId);
    }

    constructor(
        public dialogRef: MatDialogRef<SelectUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        this.api.users
            .listen({ sort: 'firstName', sortOrder: 'ASC' })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((users) => {
                this.users = users.filter(
                    (user) => user.userRole !== UserRole.Disabled,
                );
            });
    }

    confirm() {
        const selectedUser = this.users.find((user) => user.id === this.userId);
        this.dialogRef.close(selectedUser);
    }
}
