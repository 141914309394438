<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content class="mat-typography" [formGroup]="form">
    <div class="flex flex-col gap-2">
        <div class="flex flex-row items-center">
            <mat-icon class="mr-4" svgIcon="lock"> </mat-icon>
            <mat-form-field class="grow" subscriptSizing="dynamic">
                <mat-label>
                    {{ 'reset_password.new_password' | translate }}
                </mat-label>
                <input matInput type="password" formControlName="newPassword" />
            </mat-form-field>
        </div>
        <div class="flex flex-row items-center">
            <mat-icon class="mr-4" svgIcon="lock"> </mat-icon>
            <mat-form-field class="grow" subscriptSizing="dynamic">
                <mat-label>
                    {{ 'reset_password.confirm_password' | translate }}
                </mat-label>
                <input
                    matInput
                    type="password"
                    formControlName="confirmPassword"
                />
            </mat-form-field>
        </div>
        <div class="ml-10 col-md-12">
            {{ 'reset_password.description_p1' | translate }}
            <span [ngStyle]="{ color: !isMatch() ? 'red' : 'green' }">{{
                'reset_password.description_p2' | translate
            }}</span
            >{{ 'reset_password.description_p3' | translate }}
            <span
                [ngStyle]="{
                    color: new.hasError('minlength') ? 'red' : 'green'
                }"
                >{{ 'reset_password.description_p4' | translate }}</span
            >{{ 'reset_password.description_p5' | translate }}
            <br />
            <ul>
                <li
                    [ngStyle]="{
                        color: !hasUpper() && new.touched ? 'red' : 'green'
                    }"
                >
                    {{ 'reset_password.warning.upper' | translate }}
                </li>
                <li
                    [ngStyle]="{
                        color: !hasLower() && new.touched ? 'red' : 'green'
                    }"
                >
                    {{ 'reset_password.warning.lower' | translate }}
                </li>
                <li
                    [ngStyle]="{
                        color: !hasNumber() && new.touched ? 'red' : 'green'
                    }"
                >
                    {{ 'reset_password.warning.number' | translate }}
                </li>
                <li
                    [ngStyle]="{
                        color: !hasSpecial() && new.touched ? 'red' : 'green'
                    }"
                >
                    {{ 'reset_password.warning.special_character' | translate }}
                </li>
            </ul>
        </div>
    </div>
    <sz-dialog-button-bar
        id="new-password-button-bar"
        [primaryText]="
            type === 'update' ? 'btn.update' : 'reset_password.title'
        "
        [primaryDisabled]="!getRequirements() || !isMatch() || new.invalid"
        (primaryClick)="updatePassword()"
        (secondaryClick)="type === 'update' ? dialogRef.close() : cancel()"
    />
</div>
