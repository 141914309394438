import { Component, EventEmitter, Input, Output } from '@angular/core';
import Color from 'color';
import { ApplyModelDialog } from 'src/app/dialogs/apply-model-dialog/apply-model-dialog.component';
import { SelectOrganizationDialog } from 'src/app/dialogs/select-organization-dialog/select-organization-dialog.component';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Organization } from 'src/app/shared/models/customer';
import { Model } from 'src/app/shared/models/model';
import { palette } from 'src/app/shared/palette';
import {
    ModelConfig,
    OrganizationConfig,
    Setting,
    SettingType,
    TextConfig,
} from '../settings.model';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
    selector: 'sz-setting-item',
    templateUrl: './setting-item.component.html',
})
export class SettingItemComponent {
    @Input() setting: Setting;
    @Input()
    set value(newValue) {
        // This keeps valueChange from being triggered too often.
        if (this._value == newValue) {
            return;
        }
        this._value = newValue;
        if (!this.hasErrors(newValue)) {
            if (this.setting.type === SettingType.Model) {
                this.entity = this.api.models.get(newValue);
            } else if (this.setting.type === SettingType.Organization) {
                this.entity = this.api.organizations.get(newValue);
            }
            this.valueChange.emit(newValue);
        }
    }
    get value() {
        return this._value;
    }
    @Output() valueChange = new EventEmitter();

    entity: Model | Organization = null;

    // Get the color palette options.
    get palette() {
        const colorArray = Object.values(palette).map((c) => {
            const variants = [];
            const range = 4;
            for (let v = -range; v <= range; v++) {
                const newColor = Color(c).darken(v * 0.15);
                variants.push(newColor.hex());
            }
            return { preview: c, variants };
        });
        return colorArray;
    }

    // Get the customer ID.
    get faviconCustomerId() {
        return this.setting.config && 'faviconCustomerId' in this.setting.config
            ? this.setting.config.faviconCustomerId
            : 0;
    }

    constructor(
        private dialog: DialogService,
        private api: ApiService,
    ) {}

    hasErrors(value) {
        if (this.setting.type === SettingType.Text && this.setting.config) {
            const config = this.setting.config as TextConfig;
            return config.showError || value < config.minimum;
        }
        return false;
    }

    async changeEntity() {
        let dialog = null;
        const data = { data: {} };
        if (this.setting.type === SettingType.Model) {
            const modelConfig = this.setting?.config as ModelConfig;
            dialog = ApplyModelDialog;
            data.data = { models: modelConfig?.models };
        } else if (this.setting.type === SettingType.Organization) {
            const organizationConfig = this.setting
                ?.config as OrganizationConfig;
            const onlyRoles = organizationConfig?.onlyRoles;
            dialog = SelectOrganizationDialog;
            data.data = {
                title: this.setting.title,
                onlyRoles,
                organizations: organizationConfig?.organizations,
                customerId: this.value,
            };
        }
        const entity: { id: number } = await this.dialog.open(dialog, data);
        if (entity) {
            this.valueChange.emit(entity.id);
        }
    }

    private _value;
    types = SettingType;
}
