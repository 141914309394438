<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <mat-hint class="flex justify-start">
        {{ data.description | translate }}
    </mat-hint>
    <sz-input [(ngModel)]="filterTerm" />
    <div class="h-72 overflow-auto">
        <mat-selection-list
            [multiple]="false"
            (selectionChange)="userId = $event.options[0].value"
        >
            <mat-list-option
                *ngFor="
                    let user of users | search: filterTerm : ['contactFullName']
                "
                [value]="user.id"
                [selected]="userId === user.id"
                class="!h-16"
            >
                <div class="flex flex-row items-center gap-2">
                    <span>{{ user.contactFullName }}</span>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <sz-dialog-button-bar
        primaryText="{{ data.submitLabel | translate }}"
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
