<div class="h-full">
    <div class="scroll-content-no-tool-bar flex flex-col sm:flex-row">
        <sz-section-selector
            class="m-2 sm:m-3"
            [sections]="sections"
            [(selectedIndex)]="selectedIndex"
        />
        <div
            class="flex grow flex-col gap-5 overflow-auto px-6 py-9 max-sm:pt-1"
        >
            <ng-container *ngFor="let setting of filteredSettings">
                <ng-container [ngSwitch]="setting.id">
                    <ng-container *ngSwitchCase="'account.reset-password'">
                        <button
                            mat-flat-button
                            class="w-[270px] !text-white"
                            color="primary"
                            (click)="resetPassword()"
                        >
                            <mat-icon
                                svgIcon="lock-reset"
                                class="btn-icon"
                            ></mat-icon>
                            <span>
                                {{ 'account.reset_password' | translate }}
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date-time.preview'">
                        <div>
                            <div class="font-bold">
                                {{ 'settings.date_time.preview' | translate }}
                            </div>
                            <div class="py-2 text-lg">
                                {{ dateTimePreview }}
                            </div>
                            <div class="mt-0.5">
                                {{
                                    'settings.date_time.preview.description'
                                        | translate
                                }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'extras.impersonate'">
                        <button
                            mat-flat-button
                            class="w-[270px] !text-white"
                            color="primary"
                            (click)="impersonate()"
                        >
                            <mat-icon
                                svgIcon="account-switch"
                                class="btn-icon"
                            ></mat-icon>
                            <span>
                                {{ 'settings.impersonate' | translate }}
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'extras.feature-flags'">
                        <div class="flex flex-col gap-1">
                            <div class="mat-subtitle-2">
                                {{ setting.title | translate }}
                            </div>
                            <mat-checkbox
                                *ngFor="let flag of featureFlagOptions"
                                [checked]="settings.useFeature(flag)"
                                (change)="
                                    selectFeatureFlag(flag, $event.checked)
                                "
                            >
                                {{ flag }}
                            </mat-checkbox>
                            <div class="flex justify-start">
                                {{ setting.description | translate }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <sz-setting-item
                            [setting]="setting"
                            [value]="settings.get(setting.id)"
                            (valueChange)="settings.set(setting.id, $event)"
                        />
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
